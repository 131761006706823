import React, { useEffect, useState, useContext, createContext } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const AuthContext = createContext({
    signed: false,
    user: null,
    Login: async (user) => {},
    Logout: () => {}
})
  
export const AuthProvider = ({children}) => {

    const [user, setUser] = useState();

    useEffect(() => {

        const loggedUser = sessionStorage.getItem('@App:user');
        const loggedToken = sessionStorage.getItem('@App:token');

        if (loggedUser && loggedToken) {
            setUser(JSON.parse(loggedUser));
        }

    }, []);

    async function Login(userData) {

        try {
            let headersList = {
                "Accept": "*/*"
               }
            
            let formdata = new FormData();
            formdata.append("username", userData.email);
            formdata.append("password", userData.password);

            let bodyContent = formdata;
        
            let reqOptions = {
            // url: "https://api.reportapi.top/api/v1/user/login",
            url: "http://localhost:8000/api/v1/user/login",
            method: "POST",
            headers: headersList,
            data: bodyContent
            }
    
            let response = await axios.request(reqOptions);
    
            setUser({'name': 'Emanuel Oliveira', 'email': 'dm@dm.com'}); // Mudar para retornar o user pela API
    
            sessionStorage.setItem('@App:user', JSON.stringify({'name': 'Emanuel Oliveira', 'email': 'dm@dm.com'}));
            sessionStorage.setItem('@App:token', response.data.access_token);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Dados errados'
            });
        };

        

    }

    function Logout() {
        setUser(null);
    }

    return (
        <AuthContext.Provider value={{signed: Boolean(user), user, Login, Logout}}>
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth() {
    const context = useContext(AuthContext);

    return context;
}