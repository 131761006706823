// Descrição das atividades realizadas (espaço livre de texto)

import React, { useState } from "react";
import ReactQuill, {Quill} from "react-quill-new";
import QuillResizeImage from "quill-resize-image";
import 'react-quill-new/dist/quill.snow.css'

const formatDate = (date) => {
  const [year, month, day] = date.split('-');

  return `${day}/${month}/${year}`
}

Quill.register("modules/resize", QuillResizeImage);


export function Description({ data, updateField }) {

  const [value, ] = useState(data.description || data.schedule.reduce((acc, item) => {
    acc[item.data_going] = '';
    return acc;
  }, {}));

  const handleChange = (date, val) => {
    value[date] = val;
    updateField('description', value);
  };

  return (
    <div>
      {data.schedule.map((item, i) => (
        <>
          <h4><center>{formatDate(item['date_going'])}</center></h4>
          <div key={i}>
            <ReactQuill
              value={value[item.date_going]}
              onChange={(value) => handleChange(item.date_going, value)}
              modules={Description.modules}
              formats={Description.formats}
            />
          </div>
        </>
      ))}
      <br />

    </div>
  );
};

Description.modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ['bold', 'italic', 'underline'],
    ['link', 'image']
  ],
  resize: {
    locale: {},
  }
};

Description.formats = [
  'header', 'list', 'bold', 'italic', 'underline', 'link', 'image'
];