import React, { useState } from "react";
import './index.css';
import { Button } from "@mui/material";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useAuth } from "../../contexts/auth";

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { signed, Login } = useAuth();

    async function handleLogin(e) {
        
        e.preventDefault();

        await Login({
            'email': email,
            'password': password
        });
    }

    return (
        <div className="login-container">
            <div className="wrapper">
            <form onSubmit={handleLogin}>
                <div className="input-box">
                    <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required />
                    <PersonOutlineOutlinedIcon className="icon" />
                </div>
                <div className="input-box">
                    <input type="password" placeholder="Senha" onChange={(e) => setPassword(e.target.value)} required />
                    <LockOutlinedIcon className="icon" />
                </div>

                <Button type="submit">Entrar</Button>
            </form>
        </div>
        </div>
        
    )
};

export default Login;