import React from "react";
import { useAuth } from "../contexts/auth";

import LoginRoutes from "./LoginRoutes";
import ReportRoutes from "./ReportRoutes";

const Routes = () => {
    const { signed } = useAuth();

    return signed ? <ReportRoutes /> : <LoginRoutes />;
};

export default Routes;