import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Report } from "../Pages/Report";

const ReportRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Report />} />
            </Routes>
        </BrowserRouter>
    );
};

export default ReportRoutes;