import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import {tableCellClasses} from "@mui/material/TableCell";

export function Scheduler({ data, updateField }) {

    const [rows, setRows] = useState(data.schedule);

    const [scheduler, setScheduler] = useState({
        date_going: '',
        time_start_going: '',
        time_end_going: '',
        km_going: '',
        date_back: '',
        time_start_back: '',
        time_end_back: '',
        km_back: ''
    });

    const addRow = () => {
        setRows([...rows, scheduler]);
        setScheduler({
            date_going: '',
            time_start_going: '',
            time_end_going: '',
            km_going: '',
            date_back: '',
            time_start_back: '',
            time_end_back: '',
            km_back: ''
        });
        updateField('schedule', rows);

    };

    const handleChange = (index, field, value) => {
        const newScheduler = [...rows];
        newScheduler[index][field] = value;
        updateField('schedule', newScheduler);
        setRows(newScheduler);
    };

    const delScheduler = (index) => {
        const newDataScheduler = rows.filter((_, i) => i !== index);
        setRows(newDataScheduler);
        updateField('schedule', newDataScheduler);  
    }


    return (
        <TableContainer style={{width: '100%'}}>
            <Table style={{width: '100%'}}
             sx={{
        [`& .${tableCellClasses.root}`]: {
        borderBottom: "none"
            }
            }}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{fontSize: '14px', textAlign: 'center'}}>Período de chegada </TableCell>
                        <TableCell style={{fontSize: '14px', textAlign: 'center'}}>Período de saída</TableCell>
                        <TableCell style={{fontSize: '14px', textAlign: 'center'}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(rows).map((row, index) => (
                        <TableRow key={index}>
                           <TableCell>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    type="date"
                                    value={rows[row].date_going}
                                    onChange={(e) => handleChange(index, 'date_going', e.target.value)}
                                    sx={{ width: '150px', fontSize: '8px', marginRight: '4px'}}
                                    size="small"
                                    required
                                />
                                -
                                <TextField
                                type="time"
                                    value={rows[row].time_start_going}
                                    onChange={(e) => handleChange(index, 'time_start_going', e.target.value)}
                                    style={{ width: '110px', marginRight: '4px', fontSize: '14px',  marginLeft: '4px' }}
                                    size="small"
                                    required  
                                />
                                :
                                <TextField
                                    type="time"
                                    value={rows[row].time_end_going}
                                    onChange={(e) => handleChange(index, 'time_end_going', e.target.value)}
                                    placeholder="Fim"
                                    style={{ width: '110px', marginRight: '4px', marginLeft: '4px', fontSize: '14px' }}
                                    size="small"
                                    required  
                                />
                                -
                                <TextField
                                type="number"
                                    value={rows[row].km_going}
                                    onChange={(e) => handleChange(index, 'km_going', e.target.value)}
                                    style={{ width: '85px', marginLeft: '4px', marginRight: '4px', fontSize: '14px' }}
                                    size="small"
                                    required  
                                />
                                </div>
                            </TableCell>
                            <TableCell>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    type="date"
                                    value={rows[row].date_back}
                                    onChange={(e) => handleChange(index, 'date_back', e.target.value)}
                                    sx={{ width: '150px', fontSize: '8px', marginRight: '4px'}}
                                    size="small"
                                    required
                                />
                                -
                                <TextField
                                type="time"
                                    value={rows[row].time_start_back}
                                    onChange={(e) => handleChange(index, 'time_start_back', e.target.value)}
                                    style={{ width: '110px', marginRight: '4px', fontSize: '14px',  marginLeft: '4px' }}
                                    size="small"
                                    required  
                                />
                                :
                                <TextField
                                    type="time"
                                    value={rows[row].time_end_back}
                                    onChange={(e) => handleChange(index, 'time_end_back', e.target.value)}
                                    placeholder="Fim"
                                    style={{ width: '110px', marginRight: '4px', marginLeft: '4px', fontSize: '14px' }}
                                    size="small"
                                    required  
                                />
                                -
                                <TextField  
                                type="number"
                                    value={rows[row].km_back}
                                    onChange={(e) => handleChange(index, 'km_back', e.target.value)}
                                    style={{ width: '85px', fontSize: '14px', marginLeft: '4px' }}
                                    size="small"
                                    required  
                                />
                                </div>
                            </TableCell>
                            <TableCell sx={{width: '10px'}}>
                                <Button onClick={() => delScheduler(index)}>
                                    <DeleteForeverOutlinedIcon />
                                </Button>
                            </TableCell>
                        </TableRow>
                    )
                    )}
                </TableBody>
            </Table>
            
            <div>
                <Button onClick={addRow} variant="contained" color="primary" size="small" style={{marginLeft: '15px', alignItems: 'left'}}>
                    Adicionar
                </Button>
            </div>
            

        </TableContainer>
    );
};