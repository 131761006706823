import React, { useState } from "react";
import formTemplate from "./formTemplate";
import { Information } from "./components/Information";
import { Services } from "./components/Services";
import { Scheduler } from "./components/Scheduler";
import { Values } from "./components/Values";
import { Description } from "./components/Description";
import { Generate } from "./components/Generate";
import { useForm } from "./components/UseForm";
import TopBar from "./global/TopBar";
import Header from "./global/Header";
import { Button } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import "./index.css"

export function Report() {

    const [data, setData] = useState(formTemplate);

    const updateField = (key, value) => {
        setData((prev) => {
            return {...prev, [key]: value};
        });
    };

    const reportComponents = [<Information data={data} updateField={updateField} />,
                              <Services data={data} updateField={updateField} />,
                              <Scheduler data={data} updateField={updateField} />,
                              <Values data={data} updateField={updateField} />,
                              <Description data={data} updateField={updateField} />,
                              <Generate data={data} />

                            ];
    
    const {currentStep, currentComponent, changeStep, isLastStep, isFirstStep} = useForm(reportComponents);

    return (
        <div>
            <TopBar />
            <Header currentStep={currentStep} />
            <div className="form-container">
                <form onSubmit={(e) => changeStep(currentStep + 1, e)}>
                    <div>{currentComponent}</div>
                    <div className="actions">
                        {!isFirstStep && (
                            <Button type="button" onClick={() => changeStep(currentStep -1)} style={{left: 0}}>
                                <ArrowBackIosIcon />
                                <span>Voltar</span>
                            </Button>
                        )}
                        {!isLastStep && (
                            <Button type="submit">
                                <span>Avançar</span>
                                <ArrowForwardIosIcon />
                            </Button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};
