import React from "react";
import { TextField } from "@mui/material";
import '../index.css'

export function Information({data, updateField}) {
    
    return (
        <div>
            <div className="form-control" style={{flexDirection: 'row'}}>
                <div style={{display: 'flex', gap: '0.6rem', flexDirection: 'column', width: '20%'}}>
                    <label htmlFor="date">Data de Competência:<span>*</span></label>
                    <TextField
                        type="date"
                        id="date"
                        required
                        value={data.date || ""}
                        onChange={(e) => updateField("date", e.target.value)}
                        size='small'
                    />
                </div>
                <div style={{display: 'flex', gap: '0.6rem', flexDirection: 'column', width: '50%'}}>
                    <label>Telefone:</label>
                    <TextField 
                        type="tel"
                        name="phone"
                        id="phone"
                        value={data.phone || ""}
                        inputProps={{maxLength: 20}}
                        onChange={(e) => updateField("phone", e.target.value)}
                        size='small'
                    />
                </div>
                <div style={{display: 'flex', gap: '0.6rem', flexDirection: 'column', width: '30%'}}>
                    <label>Ramal:</label>
                    <TextField 
                        type="text"
                        name="text"
                        id="ramal"
                        value={data.ramal || ""}
                        inputProps={{maxLength: 6}}
                        onChange={(e) => updateField("ramal", e.target.value)}
                        size="small"
                    />
                </div>
            </div>
            <div className="form-control" style={{flexDirection: 'row'}}>
                <div style={{display: 'flex', gap: '0.6rem', flexDirection: 'column', width: '70%'}}>
                    <label>Empresa:<span>*</span></label>
                    <TextField 
                        type="text"
                        name="text"
                        id="enterprise"
                        required
                        value={data.enterprise || ""}
                        onChange={(e) => updateField("enterprise", e.target.value)}
                        size='small'
                    />
                </div>
                <div style={{display: 'flex', gap: '0.6rem', flexDirection: 'column', width: '30%'}}>
                    <label>Contato:<span>*</span></label>
                    <TextField 
                        type="text"
                        name="text"
                        id="contact"
                        required
                        value={data.contact || ""}
                        onChange={(e) => updateField("contact", e.target.value)}
                        size='small'
                    />
                </div>
            </div>
            <div className="form-control" style={{flexDirection: 'row'}}>
                <div style={{display: 'flex', gap: '0.6rem', flexDirection: 'column', width: '50%'}}>
                    <label>E-mail:</label>
                    <TextField 
                        type="email"
                        name="email"
                        id="email"
                        value={data.email || ""}
                        onChange={(e) => updateField("email", e.target.value)}
                        size="small"
                    />
                </div>
                <div style={{display: 'flex', gap: '0.6rem', flexDirection: 'column', width: '50%'}}>
                    <label>Departamento:<span>*</span></label>
                    <TextField 
                        type="text"
                        name="text"
                        id="department"
                        required
                        value={data.department || ""}
                        onChange={(e) => updateField("department", e.target.value)}
                        size="small"
                    />
                </div>
            </div>
            <div className="form-control" style={{flexDirection: 'row'}} >
                <div style={{display: 'flex', gap: '0.6rem', flexDirection: 'column', width: '50%'}}>
                    <label>Responsável técnico:<span>*</span></label>
                    <TextField 
                        type="text"
                        name="text"
                        id="technical-manager"
                        required
                        value={data.technical_manager || ""}
                        onChange={(e) => updateField("technical_manager", e.target.value)}
                        size='small'
                    />
                </div>
                <div style={{display: 'flex', gap: '0.6rem', flexDirection: 'column', width: '50%'}}>
                    <label>Contratante:<span>*</span></label>
                    <TextField 
                        type="text"
                        name="text"
                        id="contractor"
                        required
                        value={data.contractor || ""}
                        onChange={(e) => updateField("contractor", e.target.value)}
                        size='small'
                    />
                </div>
            </div>
        </div>
        
    )
}