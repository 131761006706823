const formTemplate = {
    date: "",
    enterprise: "",
    contact: "",
    phone: "",
    ramal: "",
    email: "",
    department: "",
    technical_manager: "",
    contractor: "",
    executed_service: "",
    branch_service: "",
    category_service: "",
    model_machine: "",
    model_command: "",
    axes_number: "",
    machine_location: "",
    schedule: [],
    values: [],
    description: {}
};

export default formTemplate;